<template>
	<div class="app-container interviewInfo">
		<div class="titlebox">
			<el-button size="medium" type="text" @click="$router.go(-1)">返回</el-button>
			<div class="title">{{ act == 1 ? '基本' : '岗位' }}信息-完善</div>
		</div>
		<section class="sections-container">
			<el-form ref="form" :model="form" label-width="188px" :rules="rules" v-show="act == 1">
				<div class="section-wrap">
					<el-row>
						<el-col :span="12">
							<el-form-item prop="name" label="姓名">
								<el-input v-model="form.name" class="inputWidth"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item prop="maritalStatus" label="婚姻状况">
								<el-select v-model="form.maritalStatus" placeholder="选择婚姻状况" class="inputWidth">
									<el-option
										v-for="(item, i) in maritalStatusList"
										:key="i"
										:value="item.dictId"
										:label="item.dictName"
									></el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item prop="identityNum" label="身份证号">
								<el-input v-model="form.identityNum" class="inputWidth"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item prop="birthday" label="出生时间">
								<el-date-picker
									disabled
									v-model="form.birthday"
									type="date"
									class="inputWidth"
									value-format="yyyy-MM-dd"
									format="yyyy-MM-dd"
								>
								</el-date-picker>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item prop="age" label="年龄">
								<el-input v-model="form.age" class="inputWidth" disabled></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item prop="phoneNumber" label="手机号码">
								<el-input class="input-with-select inputWidth" v-model="form.phoneNumber">
									<el-select style="width: 100px" v-model="form.areaCode" slot="prepend" placeholder="请选择">
										<el-option label="+86" value="86"></el-option>
										<el-option label="+852" value="852"></el-option>
										<el-option label="+853" value="853"></el-option>
										<el-option label="+886" value="886"></el-option>
									</el-select>
								</el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item prop="highestEducation" label="学历">
								<el-select v-model="form.highestEducation" placeholder="选择最高学历" class="inputWidth">
									<el-option
										v-for="(item, i) in highestEducationList"
										:key="i"
										:value="item.dictId"
										:label="item.dictName"
									></el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item prop="mailbox" label="个人邮箱">
								<el-input v-model="form.mailbox" class="inputWidth"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item prop="nativePlace" label="籍贯">
								<el-input v-model="form.nativePlace" class="inputWidth"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item prop="homeAddress" label="家庭住址">
								<el-input v-model="form.homeAddress" class="inputWidth"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item prop="politicalStatus" label="政治面貌">
								<el-select v-model="form.politicalStatus" placeholder="选择政治面貌" class="inputWidth">
									<el-option
										v-for="(item, i) in politicalStatusList"
										:key="i"
										:value="item.dictId"
										:label="item.dictName"
									></el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item prop="emergencyContact" label="紧急联系人">
								<el-input v-model="form.emergencyContact" class="inputWidth"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item prop="nationality" label="民族">
								<el-select v-model="form.nationality" class="inputWidth" filterable placeholder="请选择民族">
									<el-option v-for="(item, i) in nationalOptions" :key="i" :label="item.label" :value="item.value"> </el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item prop="emergencyContactPhone" label="紧急联系人电话">
								<el-input v-model="form.emergencyContactPhone" class="inputWidth"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item prop="registeredResidenceAddress" label="户口所在">
								<el-input v-model="form.registeredResidenceAddress" class="inputWidth"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item prop="workAddress" label="工作地点">
								<el-input v-model="form.workAddress" class="inputWidth"></el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :offset="11" style="margin-top: 10px">
							<!-- <el-button @click="$router.back()" v-if="!status">取消</el-button> -->
							<el-button type="primary" @click="handleClick">下一步</el-button>
						</el-col>
					</el-row>
				</div>
			</el-form>
			<el-form ref="form2" :rules="rules2" :model="form2" label-width="188px" v-show="act == 2">
				<div class="section-wrap">
					<el-row>
						<el-col :span="12">
							<el-form-item prop="entryTime" label="入职时间">
								<el-date-picker
									v-model="form2.entryTime"
									type="date"
									:picker-options="pickerOptions"
									class="inputWidth"
									disabled
									value-format="yyyy-MM-dd"
									format="yyyy-MM-dd"
									placeholder="选择日期"
								>
								</el-date-picker>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item prop="businessMailbox" label="工作邮箱">
								<el-input v-model="form2.businessMailbox" class="inputWidth"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item prop="turnPositiveTime" label="转正时间">
								<el-date-picker
									v-model="form2.turnPositiveTime"
									type="date"
									disabled
									class="inputWidth"
									value-format="yyyy-MM-dd"
									format="yyyy-MM-dd"
									placeholder="选择日期"
								>
								</el-date-picker>
							</el-form-item>
						</el-col>
						<!-- <el-col :span="12">
                  :picker-options="turnPositivePickerOptions"
                  <el-form-item prop="status" label="转正状态">
                    <el-select  v-model="form2.status" placeholder="选择转正状态"  class="inputWidth">
                      <el-option v-for="(item, i) in staffStatusList" :key="i" :value="item.dictId" :label="item.dictName"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col> -->
						<el-col :span="12">
							<el-form-item prop="hireForm" label="聘用形式">
								<el-select v-model="form2.hireForm" placeholder="选择聘用形式" class="inputWidth">
									<el-option v-for="(item, i) in hireFormList" :key="i" :value="item.dictId" :label="item.dictName"></el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item prop="jobNumber" label="工号">
								<el-input v-model="form2.jobNumber" class="inputWidth"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item prop="orgId" label="组织部门">
								<treeselect
									class="inputWidth"
									sizes="middle"
									:props="depProps"
									:options="deps"
									:value="depsId"
									:clearable="true"
									:accordion="true"
									:level="depsId"
									@getValue="getValue2($event)"
								/>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item prop="officeJobId" label="职级代码">
								<el-input v-model="form2.officeCode" class="inputWidth" disabled></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item prop="newLevel" label="职级">
								<el-input v-model="form2.newLevel" class="inputWidth" disabled></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item prop="officeId" label="职位">
								<el-input v-model="form2.officeName" class="inputWidth" disabled></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item prop="jobId" label="岗位">
								<el-input v-model="form2.jobName" class="inputWidth" disabled></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="职务" prop="positionId">
								<el-select v-model="form2.positionId" class="inputWidth">
									<el-option v-for="(item, index) in positionList" :key="index" :label="item.postName" :value="item.id">
									</el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item prop="repObjId" label="汇报对象">
								<el-select v-model="form2.repObjId" placeholder="选择汇报对象" class="inputWidth">
									<el-option v-for="(item, i) in principalList" :key="i" :value="item.staffId" :label="item.staffName"></el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item prop="centerId" label="成本中心">
								<el-cascader
									v-model="form2.centerId"
									:options="costList"
									:props="optionProps"
									filterable
									:show-all-levels="false"
									placeholder="选择成本中心"
									class="inputWidth"
								>
								</el-cascader>
							</el-form-item>
						</el-col>
						<!--<el-col :span="12">
                  <el-form-item prop="subordinate" label="直属下属量">
                    <el-input v-model="form2.subordinate" class="inputWidth"></el-input>
                  </el-form-item>
                </el-col>!-->
						<el-col :span="12">
							<el-form-item prop="hpfProvinceCode" label="社保公积金城市">
								<el-cascader
									class="inputWidth"
									filterable
									v-model="hpfcity"
									:options="provinceList"
									@change="handleChange"
								></el-cascader>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item prop="firstWorkTime" label="首次参加工作时间">
								<el-date-picker
									v-model="form2.firstWorkTime"
									type="date"
									:picker-options="firstWorkTimePickerOptions"
									class="inputWidth"
									value-format="yyyy-MM-dd"
									format="yyyy-MM-dd"
									placeholder="选择日期"
								>
								</el-date-picker>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item prop="workProvinceCode" label="工作城市">
								<el-cascader
									filterable
									class="inputWidth"
									v-model="workcity"
									:options="provinceList"
									@change="handleChange2"
								></el-cascader>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item prop="baseWage" label="岗位工资（元）">
								<el-input
									disabled
									v-model="form2.postWage"
									class="inputWidth"
									onkeyup="value=value.replace(/[^\d^\.]/g,'')"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item prop="baseWage" label="基本工资（元）">
								<el-input
									disabled
									v-model="form2.baseWage"
									class="inputWidth"
									onkeyup="value=value.replace(/[^\d^\.]/g,'')"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item prop="performanceWage" label="绩效工资（元）">
								<el-input
									disabled
									v-model="form2.performanceWage"
									class="inputWidth"
									onkeyup="value=value.replace(/[^\d^\.]/g,'')"
								></el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :offset="11" style="margin-top: 10px">
							<el-button @click="act = 1">取消</el-button>
							<el-button type="primary" @click="handleSubmit()">提交</el-button>
						</el-col>
					</el-row>
				</div>
			</el-form>
		</section>
		<el-dialog title="职级体系" :visible.sync="dialogTableVisible" top="0" width="70%">
			<el-form :inline="true" class="demo-form-inline">
				<el-form-item class="formLabel" label="职族" style="margin-right: 40px">
					<el-input v-model="searchFamilyName" placeholder="请输入职族名称"></el-input>
				</el-form-item>
				<el-form-item class="formLabel" label="职类" style="margin-right: 40px">
					<el-input v-model="searchClassName" placeholder="请输入职类名称"></el-input>
				</el-form-item>
				<el-form-item class="formLabel" label="职位" style="margin-right: 40px">
					<el-input v-model="searchOfficeName" placeholder="请输入职位名称"></el-input>
				</el-form-item>
				<el-form-item class="formLabel" label="岗位" style="margin-right: 40px">
					<el-input v-model="searchJobName" placeholder="请输入岗位名称"></el-input>
				</el-form-item>
				<el-form-item class="formLabel" style="margin-right: 40px">
					<el-button @click="getFamilyList" type="primary">查询</el-button>
				</el-form-item>
			</el-form>
			<el-table :data="gridData" height="400" @current-change="handleCurrentChange" highlight-current-row>
				<el-table-column property="familyName" label="职族"></el-table-column>
				<el-table-column property="className" label="职类"></el-table-column>
				<el-table-column property="subclassName" label="职类子类"></el-table-column>
				<el-table-column property="officeName" label="职位"></el-table-column>
				<el-table-column property="officeCode" label="职级代码"></el-table-column>
				<el-table-column property="jobName" label="岗位"></el-table-column>
				<el-table-column property="level" label="职级"></el-table-column>
			</el-table>
			<div style="text-align: right; margin-top: 10px">
				<el-pagination
					background
					@current-change="handleSysPageChange"
					:current-page="systemPageNo"
					:page-size="systemPageSize"
					layout="total, prev, pager, next"
					:total="systemTotal"
				>
				</el-pagination>
			</div>
			<div slot="footer" class="dialog-footer" style="clear: both; text-align: center">
				<el-button @click="cancelSys">取 消</el-button>
				<el-button type="primary" @click="getSystem">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>
<script>
import { costCenter } from '@/api/workbench';
import { recruitEntry, resumeDetail } from '@/api/recruitment_allocation';
import { getPositionLevelList } from '@/api/staffSystem';
import treeselect from '@/components/treeSelect/treeSelect';
import * as api from '@/api/workbench';
export default {
	components: { treeselect },
	data() {
		return {
			act: 1,
			optionProps: {
				value: 'id',
				label: 'costCenterName',
				checkStrictly: true
			},
			form: {
				resumeId: this.$route.query.resumeid,
				name: '',
				maritalStatus: '',
				identityNum: '',
				birthday: '',
				age: '',
				areaCode: '86',
				phoneNumber: '',
				highestEducation: '',
				mailbox: '',
				nativePlace: '',
				homeAddress: '',
				politicalStatus: '',
				emergencyContact: '',
				nationality: '',
				emergencyContactPhone: '',
				registeredResidenceAddress: '',
				workAddress: ''
			},
			form2: {
				entryTime: '',
				businessMailbox: '',
				turnPositiveTime: '',
				status: '',
				hireForm: '',
				jobNumber: '',
				orgId: '',
				positionId: '',
				jobEtc: '',
				centerId: '',
				repObjId: '',
				workProvinceCode: '',
				workCityCode: '',
				firstWorkTime: '',
				hpfProvinceCode: '',
				hpfCityCode: '',
				levelAndCodeId: '',
				newLevel: '',
				officeJobId: '',
				officeId: '',
				subordinate: '',
				jobId: '',
				baseWage: '',
				performanceWage: '',
				postWage: '',
				officeCode: '',
				officeName: '',
				jobName: '',
				duty: '',
				business: ''
			},
			pickerOptions: {
				disabledDate(time) {
					return (
						time.getTime() <
						new Date(new Date().setMonth(new Date().getMonth() == 0 ? 12 : new Date().getMonth() - 1)).getTime() - 86400 * 1000
					);
				}
			},
			// turnPositivePickerOptions:{
			//   disabledDate: time => {
			//     if(this.form2.entryTime){
			//       return time.getTime() < new Date(this.form2.entryTime)-(86400*1000)
			//     }else {
			//       return time.getTime() < new Date().getTime()-(86400*1000)
			//     }
			//   }
			// },
			firstWorkTimePickerOptions: {
				disabledDate: (time) => {
					if (this.form.birthday) {
						return (
							time.getTime() < new Date(this.form.birthday).setFullYear(Number(this.form.birthday.split('-')[0]) + 16) - 86400 * 1000 ||
							time.getTime() > new Date().getTime()
						);
					}
					return time.getTime() > new Date().getTime() - 86400 * 1000;
				}
			},
			deps: this.$store.state.setData.depsList,
			depsId: '',
			depProps: {
				value: 'id',
				label: 'orgName',
				children: 'children'
			},
			jobItem: {},
			maritalStatusList: [],
			sourcesList: [],
			sexList: [],
			experienceList: [],
			highestEducationList: [],
			politicalStatusList: [],
			staffStatusList: [],
			hireFormList: [],
			managementRanksList: [],
			principalList: this.$store.state.setData.principalList,
			costList: this.$store.state.setData.centerList,
			provinceList: window.$cityList,
			nationalOptions: window.$nationalList,
			hpfcity: [],
			workcity: [],
			positionList: [],
			dialogTableVisible: false,
			searchFamilyName: '',
			searchClassName: '',
			searchOfficeName: '',
			searchJobName: '',
			systemPageNo: 1,
			systemPageSize: 20,
			systemTotal: 0,
			gridData: [],
			rules: {
				name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
				identityNum: [
					{ required: true, message: '请输入身份证号码', trigger: 'blur' },
					{
						pattern: /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$)/,
						message: '证件号码格式有误！',
						trigger: 'blur'
					}
				],
				phoneNumber: [
					{ required: true, message: '请输入手机号码', trigger: 'blur' },
					{
						pattern: /^\d{3,11}$|^\d{3,11}$/,
						message: '请输入正确手机号码',
						trigger: 'blur'
					}
				],
				maritalStatus: [{ required: true, message: '请选择婚姻状态', trigger: 'change' }],
				mailbox: [{ required: true, message: '请输入邮箱', trigger: 'blur' }],
				highestEducation: [{ required: true, message: '请选择学历', trigger: 'change' }],
				homeAddress: [{ required: true, message: '请输入家庭住址', trigger: 'blur' }],
				nativePlace: [{ required: true, message: '请输入籍贯', trigger: 'blur' }],
				emergencyContact: [{ required: true, message: '请输入紧急联系人', trigger: 'blur' }],
				emergencyContactPhone: [{ required: true, message: '请输入紧急联系人电话', trigger: 'blur' }],
				politicalStatus: [{ required: true, message: '请输入政治面貌', trigger: 'blur' }],
				registeredResidenceAddress: [{ required: true, message: '请输入户口所在', trigger: 'blur' }],
				workAddress: [{ required: true, message: '请输入工作地址', trigger: 'blur' }],
				nationality: [{ required: true, message: '请输入民族', trigger: 'blur' }]
			},
			rules2: {
				businessMailbox: [{ required: true, message: '请输入邮箱', trigger: 'blur' }],
				status: [{ required: true, message: '请选择状态', trigger: 'change' }],
				hireForm: [{ required: true, message: '请选择聘用形式', trigger: 'change' }],
				jobNumber: [{ required: true, message: '请输入工号', trigger: 'blur' }],
				orgId: [{ required: true, message: '请选择组织部门', trigger: 'change' }],
				positionId: [{ required: true, message: '请选择职务', trigger: 'change' }],
				jobEtc: [{ required: true, message: '请选择职等', trigger: 'change' }],
				centerId: [{ required: true, message: '请选择成本中心', trigger: 'change' }],
				repObjId: [{ required: false, message: '请选择汇报对象', trigger: 'change' }],
				workProvinceCode: [{ required: true, message: '请选择工作城市', trigger: 'change' }],
				workCityCode: [{ required: true, message: '请选择工作城市', trigger: 'change' }],
				firstWorkTime: [{ required: true, message: '请选择时间', trigger: 'change' }],
				hpfProvinceCode: [{ required: true, message: '请选择社保公积金城市', trigger: 'change' }],
				hpfCityCode: [{ required: true, message: '请选择社保公积金城市', trigger: 'change' }],
				subordinate: [{ required: true, message: '请输入下属量', trigger: 'blur' }]
			}
		};
	},
	mounted() {
		this.queryPosition();
		const mapArr = [
			{ name: 'sourcesList', groupId: 'fromType' },
			{ name: 'sexList', groupId: 'sex' },
			{ name: 'experienceList', groupId: 'experience' },
			{ name: 'highestEducationList', groupId: 'highestEducation' },
			{ name: 'maritalStatusList', groupId: 'maritalStatus' },
			{ name: 'politicalStatusList', groupId: 'politicalStatus' },
			{ name: 'staffStatusList', groupId: 'staffStatus' },
			{ name: 'hireFormList', groupId: 'hireForm' },
			{ name: 'managementRanksList', groupId: 'managementRanks' }
		];

		mapArr.forEach((val) => {
			this[val.name] = this.$store.state.app.dict.filter((item) => item.groupId == val.groupId);
		});
		costCenter({ request: {} })
			.then((res) => {
				this.costList = res;
				this.$store.commit('SET_CENTERLIST', res);
			})
			.catch(() => {});
		this._getDetail();
	},
	methods: {
		handleClick() {
			this.$refs['form'].validate((valid) => {
				if (valid) {
					api.selectPrincipal({ request: {} })
						.then((res) => {
							this.$store.commit('SET_PRINCIPALLIST', res);
							this.principalList = res;
						})
						.catch(() => {});
					api.queryDepartment({ request: {} })
						.then((res) => {
							this.treeData = res;
							this.$store.commit('SET_DEPT', res);
							this.deps = res;
							this.act = 2;
						})
						.catch(() => {});
					this.$refs.form2.clearValidate();
				} else {
					return false;
				}
			});
		},
		_getDetail() {
			resumeDetail({ id: this.$route.query.resumeid }).then((res) => {
				for (const key in res) {
					if (this.form.hasOwnProperty(key)) {
						if (key != 'age' && key != 'birthday') {
							this.form[key] = String(res[key]);
						}
					}
					if (this.form2.hasOwnProperty(key)) {
						this.form2[key] = String(res[key]);
					}
				}
				this.form2.firstWorkTime = res.workStartTime;
			});
		},
		handleSubmit() {
			this.form2.postWage = this.form2.postWage || 0;
			this.$refs['form2'].clearValidate();
			this.$refs['form2'].validate((valid) => {
				if (valid) {
					this.$confirm(`您确认要入职${this.form.name}吗`, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					})
						.then(() => {
							const _a = { ...this.form };

							const _b = {
								...this.form2,
								centerId:
									typeof this.form2.centerId === 'string'
										? this.form2.centerId
										: this.form2.centerId[this.form2.centerId.length - 1]
							};
							const _c = Object.assign(_a, _b);

							recruitEntry(_c).then((res) => {
								if (res.code == 0) {
									this.$message.success('操作成功');
									this.$router.back();
								}
							});
						})
						.catch(() => {});
				} else {
					return false;
				}
			});
		},
		handleChange(val) {
			this.form2.hpfProvinceCode = val[0];
			this.form2.hpfCityCode = val[1];
		},
		handleChange2(val) {
			this.form2.workProvinceCode = val[0];
			this.form2.workCityCode = val[1];
		},
		getFamilyList() {
			const params = {
				familyName: this.searchFamilyName,
				className: this.searchClassName,
				officeName: this.searchOfficeName,
				jobName: this.searchJobName,
				startTime: '',
				endTime: '',
				pageNo: this.systemPageNo,
				pageSize: this.systemPageSize
			};

			getPositionLevelList(params).then((res) => {
				this.systemTotal = res.totalNum;
				this.gridData = res.list;
			});
		},
		handleCurrentChange(val) {
			this.currentRow = val;
		},
		handleSysPageChange(val) {
			this.systemPageNo = val;
			this.getFamilyList();
		},
		cancelSys() {
			this.currentRow = [];
			this.dialogTableVisible = false;
		},
		getSystem() {
			if (!this.currentRow) {
				this.$message.error('请选择职级体系信息');
				return;
			}
			this.jobItem = this.currentRow;
			this.form2.officeCode = this.jobItem.officeCode;
			this.form2.levelAndCodeId = this.jobItem.levelAndCodeId;
			this.form2.officeJobId = this.jobItem.officeJobId;
			this.form2.jobId = this.jobItem.jobId;
			this.form2.officeId = this.jobItem.officeId;
			this.dialogTableVisible = false;
		},
		getValue2(eve) {
			this.depsId = this.form2.orgId = eve.id ? eve.id : '';
			this.jobItem.orgName = eve.label ? eve.label : '';
		},
		// 获取职务
		queryPosition() {
			api.queryPosition({
				request: {}
			})
				.then((res) => {
					this.positionList = res;
					this.$store.commit('SET_POSITIONLIST', res);
				})
				.catch(() => {});
		},
		getBirthdayFromIdCard(idCard) {
			let birthday = '';

			if (idCard != null && idCard != '') {
				if (idCard.length == 15) {
					birthday = `19${idCard.substr(6, 6)}`;
				} else if (idCard.length == 18) {
					birthday = idCard.substr(6, 8);
				}

				birthday = birthday.replace(/(.{4})(.{2})/, '$1-$2-');
			}
			return birthday;
		},
		getAge(identityCard) {
			const len = `${identityCard}`.length;

			if (len == 0) {
				return '0';
			}
			if (len != 15 && len != 18) {
				// 身份证号码只能为15位或18位其它不合法
				return '';
			}

			let strBirthday = '';

			if (len == 18) {
				// 处理18位的身份证号码从号码中得到生日和性别代码
				strBirthday = `${identityCard.substr(6, 4)}/${identityCard.substr(10, 2)}/${identityCard.substr(12, 2)}`;
			}
			if (len == 15) {
				strBirthday = `19${identityCard.substr(6, 2)}/${identityCard.substr(8, 2)}/${identityCard.substr(10, 2)}`;
			}
			// 时间字符串里，必须是“/”
			const birthDate = new Date(strBirthday);

			const nowDateTime = new Date();

			let age = nowDateTime.getFullYear() - birthDate.getFullYear();
			// 再考虑月、天的因素;.getMonth()获取的是从0开始的，这里进行比较，不需要加1

			if (
				nowDateTime.getMonth() < birthDate.getMonth() ||
				(nowDateTime.getMonth() == birthDate.getMonth() && nowDateTime.getDate() < birthDate.getDate())
			) {
				age--;
			}
			return age;
		}
	},
	watch: {
		'form.identityNum': {
			deep: true,
			handler(newVal) {
				this.form.birthday = this.getBirthdayFromIdCard(newVal);
				this.form.age = this.getAge(newVal);
			}
		}
	}
};
</script>
<style lang="scss">
.interviewInfo {
	.recordBox {
		padding: 20px;
		margin-top: 10px;
		background-color: #fff;
	}
}

.sections-container {
	overflow: hidden;
	margin-bottom: 10px;
	.title-icon {
		font-size: 16px;
		margin: 0 0 20px 30px;
		&:before {
			display: inline-block;
			width: 8px;
			height: 18px;
			background: #498df0;
			content: ' ';
			border-radius: 5px;
			vertical-align: top;
			margin-right: 5px;
		}
	}
}
.sections-container {
	border-radius: 4px;
	padding: 40px 60px;
	background-color: #fff;
}
.titlebox {
	text-align: center;
	line-height: 17px;
	background: #fff;
	font: bold 18px/25px 'Microsoft YaHei';
	padding: 14px 40px;
	margin: 0 0 20px;
	> .el-button {
		float: left;
		color: #484848;
		transform: translateY(-8px);
		font-size: 16px;
	}
}
</style>
<style lang="scss" scoped>
::v-deep.el-form-item__label {
	font-weight: normal;
}
</style>
